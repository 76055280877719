// import React from "react";
// import { BrowserRouter } from "react-router-dom";
// import RenderRouter from "./routes";
// import ThemeProvider from "~/theme/ThemeProvider";
// import { AuthProvider } from "~/contexts/authContext";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { LocalizationProvider } from "@mui/x-date-pickers";

// const App: React.FC = () => {
//   return (
//     <LocalizationProvider dateAdapter={AdapterMoment}>
//       <ThemeProvider>
//         <BrowserRouter>
//           <AuthProvider>
//             <RenderRouter />
//           </AuthProvider>
//         </BrowserRouter>
//       </ThemeProvider>
//     </LocalizationProvider>
//   );
// };

// export default App;
import React from "react";
import { BrowserRouter } from "react-router-dom";
import RenderRouter from "./routes";
import ThemeProvider from "~/theme/ThemeProvider";
import { AuthProvider } from "~/contexts/authContext";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CacheBuster from "react-cache-buster";
import pjson from "../package.json";
// import Loading from './loading';
import * as buffer from "buffer";
import { Box } from "@mui/material";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
window.Buffer = buffer.Buffer;

function Loading() {
  return (
    <Box
      sx={{
        flex: 1,
        background: "white",
        position: "relative",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <img className="loading-spinner" alt="loading" src="/spinner.gif" />
      </Box>
    </Box>
  );
}

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const App: React.FC = () => {
  const isProduction = true;
  return (
    <CacheBuster
      currentVersion={pjson.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Loading />}
      metaFileDirectory={"."}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthProvider>
                <RenderRouter />
              </AuthProvider>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </CacheBuster>
  );
};

export default App;
