import React from "react";
import { Routes, Route } from "react-router-dom";
import { withFallback } from "./withFallback";
import { useAuth } from "~/contexts/authContext";
import { ProtectedRoute } from "./ProtectedLayout";

const MainLayout = withFallback(
  React.lazy(() => import("~/layouts/MainLayout"))
);
const RegisterLayout = withFallback(
  React.lazy(() => import("~/layouts/RegisterLayout"))
);

const Customers = withFallback(React.lazy(() => import("~/pages/Customers")));
const LandingPage = withFallback(React.lazy(() => import("~/pages/Dashboard")));
const Products = withFallback(React.lazy(() => import("~/pages/Products")));
const Orders = withFallback(
  React.lazy(() => import("~/pages/Orders/OrderList"))
);
const BackOrder = withFallback(
  React.lazy(() => import("~/pages/Orders/BackOrder"))
);
const BackOrderPage = withFallback(
  React.lazy(() => import("~/pages/Orders/BackOrder/BackOrderPage"))
);
const Invoice = withFallback(
  React.lazy(() => import("~/pages/Orders/Invoice"))
);
const CustomerOrderEdit = withFallback(
  React.lazy(() => import("~/pages/Orders/CustomerOrder/CustomerOrder"))
);
const CustomerOrder = withFallback(
  React.lazy(() => import("~/pages/Orders/CustomerOrder"))
);
const NewOrders = withFallback(
  React.lazy(() => import("~/pages/Orders/NewOrderrs/NewOrderLIst"))
);
const OrdersPage = withFallback(
  React.lazy(() => import("~/pages/Orders/OrdersPage"))
);
const InvoicePage = withFallback(
  React.lazy(() => import("~/pages/Orders/Invoice/InvoicePage"))
);
const NewOrdersPage = withFallback(
  React.lazy(() => import("~/pages/Orders/NewOrderPage"))
);
const Pricings = withFallback(React.lazy(() => import("~/pages/Pricings")));
const AddCustomers = withFallback(
  React.lazy(() => import("~/pages/Customers/ManageCustomers"))
);

const ProductType = withFallback(
  React.lazy(() => import("~/pages/ProductType"))
);
const Vendor = withFallback(React.lazy(() => import("~/pages/Vendor")));
const ProductCategory = withFallback(
  React.lazy(() => import("~/pages/ProductCategory"))
);

const Login = withFallback(React.lazy(() => import("~/pages/Login")));
const Register = withFallback(React.lazy(() => import("~/pages/Register")));

const ChangePassword = withFallback(
  React.lazy(() => import("~/pages/changePassword/index"))
);

const ChangeName = withFallback(
  React.lazy(() => import("~/pages/changeName/index"))
);

const User = withFallback(React.lazy(() => import("~/pages/User")));
const UserManager = withFallback(
  React.lazy(() => import("~/pages/User/UserManager"))
);
const DocumentType = withFallback(
  React.lazy(() => import("~/pages/DocumentType"))
);
const ProductTag = withFallback(
  React.lazy(() => import("~/pages/ProductTag"))
);
const ShipType = withFallback(React.lazy(() => import("~/pages/ShipType")));
const SKU = withFallback(React.lazy(() => import("~/pages/SKU")));
const PayementPage = withFallback(
  React.lazy(() => import("~/pages/Orders/PayementPage"))
);
const SearchPendingPackaging = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingPackaging"))
);
const DeliveredOrders = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchDeliveredOrders/Delivered"))
);
const SearchDeliveredOrders = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchDeliveredOrders"))
);
const PendingConfirm = withFallback(
  React.lazy(() => import("~/pages/Orders/PendingConfirm"))
);
const PendingConfirmPage = withFallback(
  React.lazy(() => import("~/pages/Orders/PendingConfirm/PendingConfirmPage"))
);
const Packaging = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingPackaging/Packaging"))
);
const SearchPendingShipping = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingShipping"))
);
const Shipping = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingShipping/Shipping"))
);
const SearchPendingDelivery = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingDelivery"))
);
const Dilevery = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingDelivery/Dilevery"))
);
const ForgotPage = withFallback(
  React.lazy(() => import("~/pages/ForgotPassword"))
);
const NewPassword = withFallback(
  React.lazy(() => import("~/pages/ForgotPassword/ForgotForm/newPasswordform"))
);

const ProductSubcategory = withFallback(
  React.lazy(() => import("~/pages/ProductSubcategory"))
);
const InventoryAlert = withFallback(
  React.lazy(() => import("~/pages/Inventory/InventoryAlert"))
);
const Inventory = withFallback(
  React.lazy(() => import("~/pages/Inventory"))
);
const ProductIncoming = withFallback(
  React.lazy(() => import("~/pages/Inventory/productIncoming"))
);



const RenderRouter: React.FC = () => {
  const { user } = useAuth();
  return (
    <Routes>
      <Route element={<RegisterLayout />}>
        <Route path="/" element={<Login />} />
      </Route>

      <Route element={<RegisterLayout />}>
        <Route path="/register" element={<Register />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route
          path="/home"
          element={
            <ProtectedRoute user={user} isAllowed={"Customers"}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home/inventory"
          element={
            <ProtectedRoute user={user} isAllowed={"Customers"}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home/products"
          element={
            <ProtectedRoute user={user} isAllowed={"Customers"}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
              <Route
          path="/home/customer"
          element={
            <ProtectedRoute user={user} isAllowed={"Customers"}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
              <Route
          path="/home/invoice"
          element={
            <ProtectedRoute user={user} isAllowed={"Customers"}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home/sales"
          element={
            <ProtectedRoute user={user} isAllowed={"Customers"}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<RegisterLayout />}>
        <Route path="/forgotPassword" element={<ForgotPage />} />
      </Route>

      <Route element={<RegisterLayout />}>
        <Route path="/rsetPassword" element={<NewPassword />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Customers"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/customers" element={<Customers />} />
        <Route path="/customers/new" element={<Customers />} />
        <Route path="/customers/:id" element={<AddCustomers />} />
        <Route path="/customers/add-customer" element={<AddCustomers />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Products"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/products" element={<Products />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Products"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/inventory-alert" element={<InventoryAlert />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Products"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/product-incoming" element={<ProductIncoming />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Order Menu"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/orders" element={<Orders />} />
        <Route path="/back-order" element={<BackOrder />} />
        <Route path="/back-order/:id" element={<BackOrderPage />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/invoice/:id" element={<InvoicePage />} />
        <Route path="/orders/add-order" element={<OrdersPage />} />
        <Route path="/orders/:id" element={<OrdersPage />} />
        <Route path="/orders/:id/payement" element={<PayementPage />} />
        <Route path="/New-Orders" element={<NewOrders />} />
        <Route path="/new-orders/:id" element={<NewOrdersPage />} />
        {/* <Route path="/pending-confirm" element={<PendingConfirm />} /> --> Disabled pending confirm */}
        <Route path="/pending-confirm" element={<Orders />} />
        <Route path="/pending-confirm/:id" element={<PendingConfirmPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Pending Packaging"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/pending-packaging" element={<SearchPendingPackaging />} />
        <Route path="/pending-packaging/:id" element={<Packaging />} />

        <Route path="/delivered-orders" element={<SearchDeliveredOrders />} />
        <Route path="/delivered-orders/:id" element={<DeliveredOrders />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/customer-order" element={<CustomerOrder />} />
        <Route
          path="/customer-order/add-order"
          element={<CustomerOrderEdit />}
        />
        <Route path="/customer-order/:id" element={<CustomerOrderEdit />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Pending Shipping"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/pending-shipping" element={<SearchPendingShipping />} />
        <Route
          path="/pending-shipping/:id/:shippingID"
          element={<Shipping />}
        />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Pending Delivery"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/pending-delivery" element={<SearchPendingDelivery />} />
        <Route
          path="/pending-delivery/:id/:shippingID"
          element={<Dilevery />}
        />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"User"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/change-name" element={<ChangeName />} />
      </Route>

      {/* <Route element={<MainLayout />}>
        <Route path="/pending-shipping" element={<SearchPendingShipping />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/pending-delivery" element={<SearchPendingDelivery />} />
      </Route> */}
      <Route element={<MainLayout />}>
        <Route path="/pricings" element={<Pricings />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Product Type"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/product-type" element={<ProductType />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Vendor"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/vendor" element={<Vendor />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Product Category"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/product-category" element={<ProductCategory />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Document Type"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/document-type" element={<DocumentType />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Product Category"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/product-tag" element={<ProductTag />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"User"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/user" element={<User />} />
        <Route path="/user/:id" element={<UserManager />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Ship Type"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/ship-type" element={<ShipType />} />
        <Route path="/sku" element={<SKU />} />
      </Route>

      {/* Product Subcategory */}
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Product Sub Category"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/Product-Subcategory" element={<ProductSubcategory />} />
      </Route>
    </Routes>
  );
};

export default RenderRouter;
